import { extraErrorDataIntegration } from "@sentry/integrations"
import { handleErrorWithSentry, init } from "@sentry/sveltekit"
import { InvalidSessionError } from "@tastyworks/tastyworks-api/src/tastyworks/http"
import { goto } from "$app/navigation"
import { RestError } from "$lib"
import { isProduction } from "$lib/environment"

if (isProduction()) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 0.1,
    release: __APP_VERSION__,
    ignoreErrors: [
      // this is a rare race condition that may occur during polling on the review page. It is not impactful to user experience
      "Failed to fetch TypeError ../../../../../../../../packages/api/src/tastyworks/http.ts this.fetchWithErrorHandler this.fetchWithErrorHandler(packages/api/src/tastyworks/http)",
      // this comes from GTM and is not impactful to user experience
      "Failed to fetch TypeError /gtag/js Mc Mc(gtag/js)",
      // This is an expected error from our app and should be ignored.
      "Invalid Session",
      // This is an expected error according to sentry source code (quoted below)
      // NOTE: If you are a Sentry user, and you are seeing this stack frame, it
      // means the sentry.javascript SDK caught an error invoking your application code. This
      // is expected behavior and NOT indicative of a bug with sentry.javascript.
      "Cookies is not defined",
      "Can't find variable: Cookies",
      // don't log expected EquifaxController error
      "Please review your contact and personal information for accuracy. Click 'next' to proceed with your application.",
    ],
    integrations: [extraErrorDataIntegration()],
    beforeSend(event, hint) {
      const { originalException } = hint

      if (originalException instanceof RestError) {
        event.message = getRestErrorMessage(originalException)
      }

      return event
    },
  })
}

function getRestErrorMessage(originalException: unknown) {
  let reportableErrorMessage = ""

  if (originalException instanceof RestError) {
    if (
      originalException.errorContainer &&
      !originalException.errorContainer.isEmpty()
    ) {
      reportableErrorMessage = originalException.errorContainer.toString()
    } else {
      reportableErrorMessage = originalException.message
    }
  }

  return reportableErrorMessage
}

async function _handleError({ error }: any): Promise<App.Error | undefined> {
  console.error(error)
  if (error instanceof InvalidSessionError) {
    sessionStorage.clear()
    await goto("/signup")
    return
  } else if (error instanceof RestError) {
    return {
      message: error.errorContainer?.toString() ?? error.message,
    }
  }

  // What handleError returns becomes exposed in $page.error.
  return {
    message: "Internal Server",
  }
}

export const handleError = isProduction()
  ? handleErrorWithSentry(_handleError)
  : _handleError
