import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [12,[2],[3]],
		"/(app)/(wizard)/account/account-access": [13,[2,4,5],[3]],
		"/(app)/(wizard)/account/account-type": [14,[2,4,5],[3]],
		"/(app)/(wizard)/account/add-beneficiaries": [15,[2,4,5],[3]],
		"/(app)/(wizard)/account/additional-information": [16,[2,4,5],[3]],
		"/(app)/(wizard)/account/trading-experience": [17,[2,4,5,6],[3]],
		"/(app)/(wizard)/account/trading-method": [18,[2,4,5],[3]],
		"/(session)/confirmation": [37,[11]],
		"/(app)/(wizard)/documents/documents-upload": [19,[2,4,7],[3]],
		"/(app)/(wizard)/documents/important-documents": [20,[2,4,7],[3]],
		"/(app)/(wizard)/done/auto-approved": [21,[2,4,8],[3]],
		"/(app)/(wizard)/done/joint-continuation": [22,[2,4,8],[3]],
		"/(app)/(wizard)/done/needs-review": [23,[2,4,8],[3]],
		"/(app)/error": [36,[2],[3]],
		"/(session)/joint-continuation-signup": [38,[11]],
		"/(session)/joint-continuation-signup/success": [39,[11]],
		"/(session)/login": [40,[11]],
		"/(app)/(wizard)/personal-info/account-activity": [24,[2,4,9],[3]],
		"/(app)/(wizard)/personal-info/affiliation-information": [25,[2,4,9],[3]],
		"/(app)/(wizard)/personal-info/citizenship-status": [26,[2,4,9],[3]],
		"/(app)/(wizard)/personal-info/contact-information": [27,[2,4,9],[3]],
		"/(app)/(wizard)/personal-info/employment-information": [28,[2,4,9],[3]],
		"/(app)/(wizard)/personal-info/tax-information": [29,[2,4,9],[3]],
		"/(app)/(wizard)/review": [30,[2,4],[3]],
		"/(app)/(wizard)/security/confirm-identity": [31,[2,4,10],[3]],
		"/(app)/(wizard)/security/security-question": [32,[2,4,10],[3]],
		"/(app)/(wizard)/security/trusted-contact": [33,[2,4,10],[3]],
		"/(app)/(wizard)/security/two-factor-auth": [34,[2,4,10],[3]],
		"/(session)/signup": [41,[11]],
		"/(app)/[...catchAll]": [35,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';